.page-section__container-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: 50rem) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    gap: 2rem;
    .page-section__text {
      grid-column-end: span 2;
    }
  }
  .page-section__text {
    align-self: center;
    padding-bottom: 1.5rem;
  }
  .page-section__image {
    align-self: center;
    border-radius: 2rem;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.page-section__even {
  @media (min-width: 50rem) {
    .page-section__text {
      grid-column-start: 2;
    }
  }
}
// .page-section__insurance-grid {
//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 2rem;
//   @media (min-width: 30rem) {
//     grid-template-columns: 1fr 1fr 1fr;
//   }
//   @media (min-width: 50rem) {
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//   }
//   img {
//     width: 100%;
//   }
// }
.page-section__insurance-cta {
  margin: 1rem 0;
}
