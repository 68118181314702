@import '../../../variables';

.main-nav {
  .nav__items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-content: center;
  }

  .nav__item {
    margin: 0 1rem;
    align-self: center;
    a {
      text-decoration: none;
      color: #fff;
      padding: 0.2rem 0;
      &:hover {
        color: #bcd030;
        border-bottom: 3px solid #bcd030;
      }
      &:active {
        color: #bcd030;
      }
    }
  }

  .nav__item--cta .button {
    display: block;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    animation: animcta 250ms 400ms 5 ease-out none;
    color: #fff;
    &:hover {
      color: #fff;
      border: 1px solid map-get($colors, primary);
    }
    &:active {
      color: #fff;
    }
  }
}

.mobile-nav {
  height: 100%;
  .nav__items {
    width: 90%;
    height: 100%;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav__item {
    margin: 1rem 0;
    a {
      font-size: 1.5rem;
      text-decoration: none;
      color: #0e4f1f;
      padding: 0.2rem 0;
      &:hover {
        color: #bcd030;
        border-bottom: 3px solid #bcd030;
      }

      &:active {
        color: #bcd030;
      }
    }
  }
  .nav__item--cta .button {
    display: block;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    animation: animcta 250ms 400ms 5 ease-out none;
    color: #fff;
    &:hover {
      color: #fff;
      border: 1px solid map-get($colors, primary);
    }
    &:active {
      color: #fff;
    }
  }
}

@keyframes animcta {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(-2deg);
  }
  100% {
    transform: rotateZ(2deg);
  }
}
