@import '../../../variables';
.carousel-testimonial {
  //height: 100%;
  width: 80%;
  max-width: 60rem;
  display: block;
  margin: auto;

  //padding-bottom: 1rem;
  .button {
    display: block;
    max-width: 10rem;
    margin: 1rem auto;
    text-align: center;
  }

  .testimonial-content {
    min-height: 12rem;
    width: 100%;
    //display: flex;
    //margin: auto;
    text-align: center;
    //justify-content: center;
    //flex-wrap: wrap;
    .testimonial-text {
      width: 100%;
      color: #fff;
      max-width: 50rem;
      text-align: center;
      margin: 1rem auto;

      //vertical-align: middle;
      //align-self: center;
      @media (min-width: 50rem) {
        min-height: 8rem;
        transform: translateY(40%);
      }
    }
    .quote-icon {
      color: map-get($colors, primary);
      font-size: 4rem !important;
    }
    .quote-icon-left {
      //margin-top: 0;
      float: left;
      // align-self: flex-start;
      // justify-self: start;
      transform: rotate(180deg);
    }
    .quote-icon-right {
      // align-self: flex-end;
      // justify-self: end;
      float: right;
      //margin-top: 2rem;
    }
  }
}
