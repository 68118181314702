.page-section__insurance-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  @media (min-width: 30rem) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 50rem) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  img {
    width: 100%;
  }
}
