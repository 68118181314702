@import '../variables';

.home-hero {
  width: 100%;
}
.home-hero__container {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.home-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(188, 208, 48, 0.1);
}
.home-hero__text {
  display: none;
}
.home-hero__title {
  display: block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2rem 0;
  text-align: center;
  vertical-align: middle;
  div {
    color: map-get($colors, secondary);
    font-size: 2.5rem;
    font-weight: 700;
    font-variant: all-small-caps;
    span {
      font-variant: none;
    }
  }
}

@media (min-width: 70rem) {
  .home-hero__title {
    display: none;
  }
  .home-hero__text {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2rem 0;
    text-align: center;
    div {
      color: map-get($colors, secondary);
      font-size: 2rem;
      font-weight: 700;
      padding: 1rem;
      span {
        font-size: 2.5rem;
      }
    }
  }
}
.home-page__testimonials {
  width: 100vw;
  margin: 0 -1rem;
  background-color: map-get($colors, secondary);
  .home-page__testimonials-inner {
    //width: 95vw;
    margin: 0 auto;
    padding-left: 1rem;
  }
  // background: map-get($colors, secondary);
  // padding-bottom: 1rem;
  // .button {
  //   display: block;
  //   max-width: 10rem;
  //   margin: 1rem auto;
  //   text-align: center;
  // }
}
.home-page__enroll {
  background-color: map-get($colors, secondary);
  display: grid;
  .page-section__image-fullwidth img {
    width: 100%;
  }
  .page-section__text-fullwidth {
    justify-self: center;
    align-self: center;
    padding: 0 3rem 5rem 3rem;
    max-width: 40rem;
    p {
      text-align: center;
      color: white;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.4;
      span {
        color: map-get($colors, primary);
        font-size: 2rem;
      }
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
}
