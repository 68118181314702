@import '../../../variables';

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid map-get($colors, primary);
  border-radius: 4px;
  background: map-get($colors, primary);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    background: lighten(map-get($colors, primary), 10%);
    border-color: lighten(map-get($colors, primary), 10%);
  }
}

.button--inverse {
  background: transparent;
  color: map-get($colors, primary);
  &:hover,
  ß &:active {
    color: white;
    background: map-get($colors, primary);
  }
}

.button--dark {
  border-color: map-get($colors, secondary);
  border-radius: 4px;
  background: map-get($colors, secondary);
  &:hover,
  ß &:active {
    background: lighten(map-get($colors, secondary), 10%);
    //border-color: lighten(map-get($colors, secondary), 10%);
  }
}

.button--danger {
  background: #830000;
  border-color: #830000;
  &:hover,
  &:active {
    background: lighten(#830000, 10%);
    border-color: lighten(#830000, 10%);
  }
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

// a.button{
//   display: block;
//   margin: 0 auto;
//   text-align: center;
//   max-width: 12rem;
// }
