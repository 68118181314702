.main-footer {
  background: #fff;
  border-top: 3px solid #bcd030;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

#footer-1,
#footer-2,
#footer-3 {
  //margin: 0.5rem auto;
  img {
    max-height: 8rem;
    max-width: 100%
  }
}

@media (min-width: 40rem) {
  .main-footer {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  #footer-1,
  #footer-2,
  #footer-3 {
    //margin: 3rem auto;
    justify-content: center;
    img {
      height: 10rem;
    }
  }
  .main-footer__link {
    margin: 0 1rem;
  }

  .main-footer__links {
    flex-direction: row;
    justify-content: center;
  }
}
