$colors: (
  primary: #bcd030,
  secondary: #063754,
);

$map-colors: (
  northeast: #71c2ed,
  northcentral: #bcd030,
  southcentral: #ff963f,
  southeast: #8e4a7f,
  west:#d81952,
);

//secondary: #192d3f,
