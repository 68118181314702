@import '../../../variables';

.main-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding: 0.5rem 0 0 0;
  z-index: 60;
  grid-area: header;
  display: grid;
  // grid-template-columns: 1fr 8fr;
  // grid-template-rows: 5rem 1fr;
  grid-template-areas:
    'header-brand header-brand'
    'header-burger header-user';
  grid-row-gap: 0.5rem;
  // align-items: center;
  // justify-content: space-between;
  //justify-items: center;
  align-items: center;
}

.main-header_contact {
  display: none;
}

.main-header__burger {
  grid-area: header-burger;
  justify-self: start;
}

.main-header__user {
  grid-area: header-user;
  justify-self: end;
  padding-right: 1rem;
  cursor: pointer;
  // span {
  //   font-size: 3rem;
  //   color: map-get($colors, primary);
  // }
  div {
    background-color: map-get($colors, primary);
    //color: map-get($colors, secondary);
  }
}

.main-header__brand {
  color: map-get($colors, primary);
  text-decoration: none;
  font-weight: bold;
  grid-area: header-brand;
  /* width: 20px; */
  justify-self: center;
  padding: 0.1rem 0.5rem;
}

.main-header__brand img {
  height: 4.5rem;
  vertical-align: middle;
  /* width: 100%; */
}

.main-nav {
  display: none;
}

@media (min-width: 70rem) {
  .main-header {
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'header-contact header-brand header-user'
      'header-nav header-nav header-nav';
  }
  .main-nav {
    display: flex;
    height: 4rem;
    grid-area: header-nav;
    justify-content: center;
    align-content: center;
    background-color: map-get($colors, secondary);
  }
  .main-header_contact {
    display: block;
    grid-area: header-contact;
    justify-self: start;
    text-align: center;
    line-height: 1.5rem;
    color: map-get($colors, primary);
    padding: 0 1.5rem;
    a {
      margin: 0;
      color: inherit;
      span {
        display: block;
        font-size: 1.5rem;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  .main-header__brand {
    justify-self: center;
    align-self: center;
  }
  .main-header__user {
    padding-right: 1.5rem;
    span {
      font-size: 3rem;
    }
  }
  .main-header__burger {
    display: none;
  }
}

@keyframes animcta {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(-2deg);
  }
  100% {
    transform: rotateZ(2deg);
  }
}
