@import '../../../variables';
#copyright {
  background: #192d3f;
  padding: 2rem;
  /* grid-row: 3 / 4; */
}

.main-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-footer__link {
  margin: 0.5rem 0;
  a,
  p {
    color: white;
    text-decoration: none;
    &:hover,
    &:active {
      color: #ccc;
    }
  }
}

@media (min-width: 40rem) {
  .main-footer__link {
    margin: 0 1rem;
  }

  .main-footer__links {
    flex-direction: row;
    justify-content: center;
  }
}
