.page-section__anchor::before {
  display: block;
  content: ' ';
  margin-top: -9.5rem;
  height: 9.5rem;
  visibility: hidden;
  pointer-events: none;
}

.page-section__content {
  width: 100%;
  padding: 1rem;
}

.page-section__content-boxed {
  max-width: 75rem;
  margin: 0 auto;
}
