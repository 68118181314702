.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 120;
  height: 100vh;
  width: 75%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  // &.show {
  //   display: block;
  // }
  // &.hide {
  //   display: none;
  // }
}
